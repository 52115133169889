<template>
    <ODataLookup :data-object="dsRisksLkp" :bind="(sel: DataItemModel) => { addRisk(sel); }" :noClear="true"
        contextField="OrgUnitIdPath">
        <OColumn field="ID" :headerName="$t('ID')" width="80" />
        <OColumn field="Title" :headerName="$t('Title')" width="300" />
        <OColumn field="OrgUnit" :headerName="$t('Org Unit')" width="200" />
        <template #target="{ target }">
            <div class="d-inline-flex justify-content-center align-items-center"
                :title="$t('RAMS element(s) related to the Measure')">
                <span class="text-muted">{{ $t('Related RAMS element(s)') }} </span>
                <span :ref="target" type="button" class="btn btn-sm btn-link border-0 text-primary" aria-hidden="true"
                    style="cursor: pointer;"> {{ $t('Add') }}</span>
            </div>
        </template>
    </ODataLookup>

    <div v-if="dsRAMSRelations.state.isLoaded" v-for="(item, index) in dsRAMSRelations.data" :key="index">
        <div class="d-flex align-items-baseline c-related-item" v-if="!item.isNewRecord">
            <a target='_blank' :href="`/ramslogitem?ID=${item.Risk_ID}`">
                <span>{{ item.Risk_ID }} - {{ item.RAMSTitle }}</span>
            </a>
            <button class="btn btn-link text-muted px-0 py-0 ms-2" :title="$t('Remove risk')" @click="removeRisk(item)">
                <span class="visually-hidden">{{ $t('Remove') }}</span>
                <i class="bi bi-x-circle"></i>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import type { DataItemModel } from 'o365-dataobject';

const dsRAMSRelations = $getOrCreateDataObject({
    id: 'dsRAMSRelations',
    viewName: 'aviw_RAMS_ScopeItems',
    uniqueTable: 'atbv_RAMS_ScopeItems',
    allowInsert: true,
    allowDelete: true,
    maxRecords: -1,
    fields:
        [
            { name: "Risk_ID", type: "number" },
            { name: "Item_ID", type: "number" },
            { name: "RAMSTitle", type: "string" }
        ]
});

const dsRisksLkp = $getOrCreateDataObject({
    id: 'dsRisksLkp',
    viewName: 'aviw_RAMS_Risks',
    maxRecords: -1,
    fields:
        [
            { name: "ID", tpye: "number" },
            { name: "Title", type: "string" },
            { name: "OrgUnit", type: "string" }
        ]
})

const props = defineProps({
    itemId: Number,
});

dsRAMSRelations.recordSource.whereClause = "Item_ID =" + props.itemId;
dsRAMSRelations.load();

const addRisk = async (risk: DataItemModel) => {
    const row = dsRAMSRelations.createNew({
        ScopeItem_ID: props.itemId,
        Risk_ID: risk.ID
    }, false);
    row.save()
        .then(() => { dsRAMSRelations.load(); })
        .catch((ex) => { alert(ex, 'danger', { autohide: true, delay: 3000 }) })
}

const removeRisk = (risk: DataItemModel) => {
    dsRAMSRelations.delete(risk.index).then(() => {
        dsRAMSRelations.refreshRow(risk.index);
    }).catch((ex) => {
        alert(ex, 'danger', { autohide: true, delay: 3000 });
        dsRAMSRelations.load();
    });
}


</script>